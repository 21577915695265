html {
  backdrop-filter: blur(10px);
}
.App {
  text-align: center;
  direction: rtl;
}
.errorPage,
.errorPage_container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  pointer-events: none;
}
.error_404 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
}
.error_404 > h1 {
  position: absolute;
  top: -10px;
  font-size: 4rem;
  font-weight: bold;
  color: rgb(234, 2, 2);
}
.error_404 img {
  width: 250px;
}
.errorPage_container p {
  font-size: 1.2rem;
  color: #fff;
}
.errorPage_container span {
  color: #aaa;
  font-size: 1.2rem;
}
.view_content {
  width: 100%;
  display: none;
}
@media screen and (max-width: 789px) {
  .errorPage {
    display: none;
  }
  .view_content {
    display: block;
  }
}
